#root {
  height: 100%;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.m-10 {
  margin: 10px;
}

.m-1em {
  margin: 2rem;
}

.mb-1em {
  margin-bottom: 1em;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-2em {
  margin-top: 2rem;
}

.p-10 {
  padding: 10px;
}

.radius-5 {
  border-radius: 5px;
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

h1 {
  font-size: 2em;
}


// ANTD style update

.ant-layout {
  background-color: #fff;
}


// login

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  // background: #F6F9FE;
}

.login-box {
  min-width: 400px;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
  padding: 2em 0;
}

.login-form-button {
  width: 100%;
  border-radius: 6px;
}

// Credit Card

.cb-card {
  border: 1px solid #CACDD5;
  padding: 1em;
  display: flex;
  border-radius: 6px;

  &.selectable {
    cursor: pointer;
  }

  &.selected {
    background-color: #EDF3FF;
    border-color: #B0BCD8;
  }

  .icon {
    margin-right: .5em;
  }

}

.card-select {
  display: flex;
}